import React, { PropsWithChildren } from "react";
import CommonView from "@/views/Common";

import Row from "./Row";
import Col from "./Col";
import Name from "./Name";
import Colors from "./Colors";
import Price from "./Price";
import Sizes from "./Sizes";
import Actions from "./Actions";
import Labels from "./Labels";
import Features from "./Features";
import Top from "./Top";
import SaleAttr from "./SaleAttr";

const Product: React.FC<unknown> = ({ children }: PropsWithChildren<unknown>) => {
    return <CommonView.SectionContainer className="product">{children}</CommonView.SectionContainer>;
};

export default Object.assign(Product, {
    Row,
    Col,
    Name,
    Colors,
    Price,
    Sizes,
    Actions,
    Labels,
    Features,
    Top,
    SaleAttr,
});
