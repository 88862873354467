import BaseApiService from "@/api/base";
import { TokenInterceptor } from "@/api/http/interceptors";
import { Config } from "@/modules/constants";
import { SearchProductModel } from "@/api/services/common/models/SearchProduct.model";

class SearchApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
    }

    search = async (params: { q: string }): Promise<SearchProductModel[]> => {
        const { data } = await this.http.get("/search/entity/", { params });

        return this.plainToClassArray(SearchProductModel, data.data);
    };
}

export default new SearchApiService({
    baseURL: Config.CMS_API_URL,
});
