import { Exclude, Expose, Transform, Type } from "class-transformer";
import OrderItem from "@/api/services/order/models/OrderItem.model";
import { Shop } from "@/api/services/user/models";
import { CityName } from "@/modules/constants";
import dayjs from "@/modules/utils/dayjs";

export enum OrderStatus {
    new = 0,
    paid_up = 1,
    wait_processing = 3,
    confirmed = 4,
    canceled = 5,
    assembling = 6,
    on_way = 8,
    return = 10,
    closed = 11,
    pending_payment = 12,
    pending_product = 13,
    processing = 14,
    ready_delivery = 15,
    exchange = 16,
    undelivered = 17,
}

export enum OrderDeliveryMethod {
    undefined = 0,
    pickup = 1,
    delivery = 2,
}

@Exclude()
class Order {
    @Expose() id: number;

    @Expose({ name: "user" }) userId: number;

    @Expose({ name: "order_number" }) number: string;

    @Expose({ name: "commentary_for_client" }) commentaryForClient: string;

    @Expose({ name: "total_price" }) totalPrice: number;

    @Expose({ name: "payment_method" }) paymentMethod: number;

    @Expose({ name: "cost_of_delivery" }) costOfDelivery: number;

    @Expose()
    get paymentMethodName(): string {
        const methods: { [key: number]: string } = {
            0: "Оплата при получении",
            1: "Оплата картой на сайте",
            2: "Оплата по счету от юридического лица",
            3: "Оплата по счету от физического лица",
        };
        return methods[this.paymentMethod];
    }

    @Expose({ name: "full_name" }) fullName: string;

    @Expose() email: string;

    @Expose() phone: string;

    @Expose({ name: "comment_to_the_courier" }) commentToTheCourier: string;

    @Expose({ name: "pick_up_point" }) pickUpPoint: string;

    @Expose() status: OrderStatus;

    @Expose()
    get statusName(): string {
        const status: { [key in OrderStatus]: string } = {
            0: "Новый",
            1: "Оплачен",
            3: "Ожидает обработки",
            4: "Подтвержден",
            5: "Отменён",
            6: "Сборка заказа",
            8: "В пути",
            10: "Возврат",
            11: "Завершен",
            12: "Ожидает оплаты",
            13: "Ожидание товара",
            14: "Обработка",
            15: "Готов к доставке",
            16: "Обмен",
            17: "Не доставлен",
        };
        return status[this.status];
    }

    @Expose()
    get isPaid(): boolean {
        return [OrderStatus.paid_up, OrderStatus.assembling, OrderStatus.on_way].includes(this.status);
    }

    @Expose({ name: "delivery_method" }) deliveryMethod: OrderDeliveryMethod;
    @Expose({ name: "delivery_services_display" }) deliveryServicesDisplay: string;
    @Expose({ name: "promo_code" }) promoCode: string;

    @Expose()
    get deliveryMethodName(): string {
        const methods: { [key in OrderDeliveryMethod]: string } = {
            0: "Неопределенный",
            1: "Самовывоз",
            2: "Доставка",
        };
        return methods[this.deliveryMethod];
    }

    @Expose()
    get deliveryAddress(): string {
        if (this.deliveryMethod === OrderDeliveryMethod.undefined) {
            return "";
        }
        if (this.deliveryMethod === OrderDeliveryMethod.pickup) {
            return `${this.shop?.title} ${this.shop?.address}`;
        }
        if (this.deliveryMethod === OrderDeliveryMethod.delivery) {
            return `${CityName[this.city]} ${this.street} ${this.house} ${this.apartmentOffice}`;
        }
        return "";
    }

    @Expose() city: string;

    @Expose() street: string;

    @Expose() house: string;

    @Expose({ name: "apartment_office" }) apartmentOffice: string;

    @Type(() => OrderItem)
    @Expose()
    items: OrderItem[];

    @Type(() => Shop)
    @Expose({ name: "shop_obj" })
    shop: Shop | null;

    @Expose({ name: "created_at" })
    @Transform(({ value }) => dayjs(value).format("DD MMMM"))
    createdAt: string;
}

export default Order;
