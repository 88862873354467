import React from "react";
import { Route, Switch } from "react-router-dom";
import { useCompare, useFavourite } from "./modules/hooks";
import UniversalPage from "./pages/UniversalPage";

import "./styles/index.scss";

export type AppProps = Window["__INITIAL_DATA__"];

const App: React.FunctionComponent<AppProps> = ({ page }: AppProps) => {
    useFavourite({ autoLoad: true });
    useCompare({ autoLoad: true });

    return (
        <Switch>
            <Route path="*" render={(props) => <UniversalPage {...props} page={page} />} />
        </Switch>
    );
};

export default App;
