import BaseApiService from "@/api/base";
import { TokenInterceptor } from "@/api/http/interceptors";
import { Config } from "@/modules/constants";
import { ProductModel } from "@/api/services/common/models/Product.model";

class PaymentsApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
    }

    paymentData = async (payment_uuid: string): Promise<ProductModel[]> => {
        const { data } = await this.http.get("/cloudpayments/payment_data/", {
            params: { payment_uuid },
        });

        return data;
    };
}

export default new PaymentsApiService({
    baseURL: Config.CMS_API_URL,
});
