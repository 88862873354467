import React from "react";

export type BrandProps = React.ImgHTMLAttributes<HTMLImageElement>;

const Brand: React.FunctionComponent<BrandProps> = (props: BrandProps) => {
    return (
        <div className="catcard__brand">
            <img className="catcard__brand-img" {...props} />
        </div>
    );
};

export default Brand;
