import BaseApiService from "@/api/base";
import { TokenInterceptor } from "@/api/http/interceptors";
import { PaginatedQueryResult } from "@/api/shared/models";
import { CmsQueryParams } from "@/api/types";
import { Config } from "@/modules/constants";
import { EntitiesQueryParams } from "../entities";
import { CreateProductReviewDto } from "./dto";
import { Product, ProductReview } from "./models";
import { ModifiedProductEntity, ProductDetailGroup, ProductFilter, ProductList } from "./models/Product.model";

class CatalogApiService extends BaseApiService {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
    }

    getProducts = async (params: EntitiesQueryParams = {}): Promise<PaginatedQueryResult<ProductList>> => {
        const { data } = await this.http.get("/catalog/product/", { params });
        return this.plainToClassFromExist(new PaginatedQueryResult(ProductList), data);
    };

    getProductFilters = async (params): Promise<ProductFilter> => {
        params["attr"] = params["attr"] ? JSON.stringify(params["attr"]) : undefined;
        params["sku_attr"] = params["sku_attr"] ? JSON.stringify(params["sku_attr"]) : undefined;
        const { data } = await this.http.get("/catalog/product/filters/", { params });
        return this.plainToClassFromExist(new ProductFilter(), data);
    };

    getOneProduct = async (id: number): Promise<Custom.Maybe<Product>> => {
        const { data } = await this.http.get("/catalog/core/products/", { params: { ids_list: id } });

        const entities = this.plainToClassArray<ModifiedProductEntity>(ModifiedProductEntity, data.result.items);
        const items = entities.map((entity) => new Product(entity));

        return this.getFirstItemOrNull(items);
    };

    getProduct = async (id: number): Promise<Custom.Maybe<ProductDetailGroup>> => {
        const { data } = await this.http.get(`/catalog/product/${id}/`);
        return this.plainToClass(ProductDetailGroup, data);
    };

    getProductReviews = async (
        params: CmsQueryParams<ProductReview> = {}
    ): Promise<PaginatedQueryResult<ProductReview>> => {
        const { data } = await this.http.get(`/multisite/review_product/`, { params });

        return this.plainToClassFromExist(new PaginatedQueryResult(ProductReview), data);
    };

    getOneProductReview = async (id: number): Promise<ProductReview> => {
        const { data } = await this.http.get(`/multisite/review_product/${id}/`);

        return this.plainToClass(ProductReview, data);
    };

    createProductReview = async (createReviewDto: CreateProductReviewDto): Promise<ProductReview> => {
        const { data } = await this.http.post("/multisite/review_product/", createReviewDto);

        return this.plainToClass(ProductReview, data);
    };

    getProductReviewByProduct = async (id: number): Promise<any> => {
        const { data } = await this.http.get(`/multisite/review_product/by_product/${id}/`);

        return data;
    };
}

export default new CatalogApiService({
    baseURL: Config.CMS_API_URL,
});
