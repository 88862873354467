import React from "react";

export type PriceProps = Partial<{
    normal: string | number | null;
    old: string | number | null;
    sale: string | number | null;
}>;

const Price: React.FunctionComponent<PriceProps> = ({ normal, old, sale }: PriceProps) => {
    return (
        <div className="product-prices">
            {normal ? (
                <div className="product-price">
                    {normal} <span>₽</span>
                </div>
            ) : null}
            {!normal && sale ? (
                <div className="product-pricesale">
                    {sale} <span>₽</span>
                </div>
            ) : null}
            {!normal && old ? (
                <div className="product-priceold">
                    {old} <span>₽</span>
                </div>
            ) : null}

            {!normal && !sale ? <div className="product-price">Цена не указана</div> : null}
        </div>
    );
};

export default Price;
