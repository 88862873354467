import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Thumbs } from "swiper/core";

SwiperCore.use([Thumbs]);

export type Photo = string;

export type CarouselProps = Partial<{ thumbsSwiper: Swiper | null; photos: Photo[] }>;

const Carousel: React.FunctionComponent<CarouselProps> = ({ thumbsSwiper, photos = [] }: CarouselProps) => {
    return (
        <div className="product-photos">
            <Swiper
                thumbs={{
                    swiper: (thumbsSwiper as unknown) as any,
                }}
                breakpoints={{
                    320: {
                        effect: "slide",
                    },
                    568: {
                        effect: "fade",
                    },
                }}
            >
                {photos.map((photo, i) => (
                    <SwiperSlide key={i} className=" product-photo">
                        <img className="product-photo__img" src={photo} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default Carousel;
